import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import DSe from "../Dse";
// import Howtobuy from "../container/HowToBuy/index";
// import About from "../container/About/index";

export default function Rout() {
  return (
      <Router>
        <Routes>
          {/* <Route path="/login">
          <Login />
        </Route> */}
          <Route exact path="/" element={<DSe />} />
          {/* <Route exact path="/dse-about" element={<About />} />
          <Route exact path="/how-we-buy-diamonds" element={<Howtobuy />} /> */}
        </Routes>
      </Router>
  );
}
