import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Announcement from "./container/Announcement";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { NavHashLink as Link } from "react-router-hash-link";
import ScrollToTop from "react-scroll-to-top";
import History from "./routes/History";
import NavigationIcon from "@mui/icons-material/Navigation";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

export default function Dse() {
  const [showAnn, setShowAnn] = useState(false);
  const announcement = useSelector((state) => state.Announcement);

  useEffect(() => {
    if (announcement) {
      let generalAnn = announcement.site_general_announcement;
      if (generalAnn && generalAnn.setting_value) {
        setShowAnn(true);
      } else {
        setShowAnn(false);
      }
    }
  }, [announcement]);

  const handleScroll = () => {
    History.push("/");
  };

  const handleCloseAnn = () => {
    setShowAnn(false);
  };

  function handleDownload() {
    window.open("https://docsend.com/view/uyw892k3c9m7v6xi", "_blank");
  }

  const handleMenu = ()=> {
    const menuOptions = document.getElementById("DseTopnav");
    if (menuOptions.className === "topnav") {
      menuOptions.className += " responsive";
    } else {
      menuOptions.className = "topnav";
    }
  }
  return (
    <div>
      <header className="text-gray-600 body-font">
        <div className="topnav" id="DseTopnav">
          <div class="container  flex flex-wrap p-3 flex-col md:flex-row items-center">
            <a class="flex title-font font-medium items-center text-gray-900 mb-5 md:mb-0">
              <img className="logo" src="/logo/DSElogo.svg" />
            </a>
            <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 mb-5 flex flex-wrap items-center text-base justify-center">
              <a href="#About" class="mr-5 hover:text-gray-900 font-bold">
                About
              </a>
              <a
                href="https://docsend.com/view/uyw892k3c9m7v6xi"
                target="_blank"
                rel="noopener noreferrer"
                class="mr-5 hover:text-gray-900 font-bold"
              >
                FAQ
              </a>
              <a href="#BuyingProcess" ></a>
            </nav>
            <button class="inline-flex items-center  focus:outline-none text-base mr-5 mb-4 md:mt-0">
              <a
                style={{ color: "#2b2b3a" }}
                href={process.env.REACT_APP_DSE_SELL_DOMAIN + "/#/login"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign In
              </a>
            </button>
            <button class="inline-flex items-center bg-[#703dba] relative focus:outline-none text-base mb-4 md:mt-0">
              <p class="font-bold font-EffraBold text-left capitalize text-white">
                {" "}
                <a
                  style={{ color: "white" }}
                  href={process.env.REACT_APP_DSE_SELL_DOMAIN + "/#/register"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up
                </a>
              </p>
            </button>
            
            <a className="icon open__menu" onClick={() => handleMenu()}>
              <MenuSharpIcon />
            </a>

            <a className="icon close__menu" onClick={() => handleMenu()}>
              <CloseSharpIcon />
            </a>
          </div>
        </div>
      </header>

      <div className="scroll__to__top" onClick={() => handleScroll()}>
        <ScrollToTop
          smooth
          component={<NavigationIcon style={{ fill: "#fff" }} />}
        />
      </div>
      
      {/* remove the below header code if not in use */}
      <header class="text-gray-600 body-font hide">
        <div class="container  flex flex-wrap p-3 flex-col md:flex-row items-center">
          <a class="flex title-font font-medium items-center text-gray-900 mb-5 md:mb-0">
            <img src="/logo/DSElogo.svg" />
          </a>
          <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 mb-5 flex flex-wrap items-center text-base justify-center">
            {/* <Link to="/how-we-buy-diamonds" class="mr-5 hover:text-gray-900"
              
              smooth
              activeStyle={{ fontWeight: "bold" }}
              className="menu__item"
            >How we buy diamonds</Link> */}
            <a href="#About" class="mr-5 hover:text-gray-900 font-bold">
              About
            </a>
            <a
              href="https://docsend.com/view/uyw892k3c9m7v6xi"
              target="_blank"
              rel="noopener noreferrer"
              class="mr-5 hover:text-gray-900 font-bold"
            >
              FAQ
            </a>
            {/* <Link class="mr-5 hover:text-gray-900" to={`/dse-about`} smooth className="menu__item">
              About
            </Link> */}
          </nav>
          <button class="inline-flex items-center  focus:outline-none text-base mr-5 mb-4 md:mt-0">
            <a
              style={{ color: "#2b2b3a" }}
              href={process.env.REACT_APP_DSE_SELL_DOMAIN + "/#/login"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign In
            </a>
          </button>
          <button class="inline-flex items-center bg-[#703dba] relative focus:outline-none text-base mb-4 md:mt-0">
            <p class="font-bold font-EffraBold text-left capitalize text-white">
              {" "}
              <a
                style={{ color: "white" }}
                href={process.env.REACT_APP_DSE_SELL_DOMAIN + "/#/register"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign Up
              </a>
            </p>
          </button>
        </div>
      </header>
      <section class="text-gray-600 body-font">
        <h1 class="flex-grow-0 flex-shrink-0 2xl:text-6xl text-[33px]  md:text-[44px] font-bold text-center text-[#2b2b3a]">
          Exchange Overview
        </h1>
        <div class="container flex  mx-auto flex 2xl:px-5 py-10 md:flex-row flex-col ">
          <div class=" p-8 border-0 bg-gray-100 rounded-[22px] lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <img src="/Images/Icon1.png" />
            <p class="flex-grow-0 flex-shrink-0 w-fit text-xl text-left text-[#2b2b3a]">
              The{" "}
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                Diamond Standard Exchange
              </span>{" "}
              is where all{" "}
              <span style={{ textDecoration: "underline" }}>
                natural diamonds
              </span>{" "}
              are purchased for Diamond Standard commodities. We buy only from{" "}
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                registered vendors
              </span>
              , who are established diamond manufacturers and dealers{" "}
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                around the world
              </span>
              . Vendors offer us diamonds for instant cash purchase or bids. We
              do not buy diamonds from consumers.
            </p>
            <img src="/Images/Icon2.png" />
            <p class=" flex-grow-0 flex-shrink-0 w-fit text-xl text-left text-[#2b2b3a]">
              We purchase diamonds using a
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                {" "}
                regulator-approved
              </span>{" "}
              and{" "}
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                {" "}
                audited
              </span>
              &nbsp; process. Our system automatically buys or bids on thousands
              of round diamond types, to aquire statistical samples of a large
              range of natural supply, and then{" "}
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                {" "}
                we pay COD with no returns.
              </span>
            </p>
            <img src="/Images/Icon3.png" />
            <p class=" flex-grow-0 flex-shrink-0 w-fit text-xl text-left text-[#2b2b3a]">
              All vendors{" "}
              <span class="flex-grow-0 flex-shrink-0 w-[703px] text-xl font-bold text-left text-[#2b2b3a]">
                {" "}
                must be existing GIA and IGI Clients,
              </span>{" "}
              and deliver the diamonds to their local IGI lab for confirmation.
              We handle global settlement and customs.
            </p>
            {/* <div class="flex justify-center">
              <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                Button
              </button>
              <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
                Button
              </button>
            </div> */}
          </div>
          <div class="lg:max-w-lg lg:w-full md:w-1/2 md:w-5/6 ">
            {/* <img
              class="absolute md:left-[540px] center-[606px] lg:center-[606px] hidden md:block 2xl:hidden lg:top-[304.5px] w-[670px]"
              src="/Icons/LongArrow.svg"
            /> */}
            {/* <img
              class="object-cover object-center rounded md:shrink-0"
              alt="hero"
              src="/Images/Image1.png"
            /> */}
            <img
              alt="ecommerce"
              class=" object-cover object-center rounded"
              src="/Images/Image8.png"
            />
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="flex-grow-0 flex-shrink-0 text-[44px] 2xl:text-6xl font-bold text-center text-[#2b2b3a]">
              Simple System
            </h1>
            <text class="lg:w-2/3 mx-auto mt-[30px] px-[60px] text-[22px] text-[#6b6b6b] leading-relaxed text-base">
              We buy large volumes of graded and ungraded natural diamonds from
              established global vendors using an automated price discovery
              process.
            </text>
          </div>
          {/* <div class=" flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 flex-wrap">
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[280px]  gap-[10px] px-10 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon4.png" />
              <p class="leading-relaxed text-base mb-4">
                <h2 class="md:text-lg font-bold sm:text-xl capitalize text-[#703dba] title-font mb-2">
                  Upload
                </h2>
                <h3 class="text-xs text-left capitalize text-[#6b6b6b]">
                  Upload diamond list with offers
                </h3>
              </p>
            </div>
            <img class="hidden md:block" src="/Icons/Arrowone.svg" />
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[280px] gap-[10px] px-10 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon5.png" />

              <p class="leading-relaxed text-right text-base mb-4">
                <h2 class="md:text-lg font-bold sm:text-xl capitalize text-[#703dba] font-medium title-font mb-2">
                  Purchase
                </h2>
                <h3 class="text-xs text-left capitalize text-[#6b6b6b]">
                  Get an instant purchase order
                </h3>
              </p>
            </div>
            <img class="hidden md:block" src="/Icons/Arrowtwo.svg" />
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[280px] gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon6.png" />

              <p class="leading-relaxed text-base mb-4">
                <h2 class="md:text-lg font-bold sm:text-xl capitalize text-[#703dba] font-medium title-font mb-2">
                  Deliver
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Deliver to local IGI gem lab
                </h3>
              </p>
            </div>

            <div class="flex flex-col text-center w-full mt-5">
              <p class="flex-grow-0 flex-shrink-0 text-xl font-bold text-center text-[#6b6b6b]">
                Our computer only selects the diamonds with the best prices.
              </p>
            </div>
          </div> */}

          <div className="workflow">
            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon4.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Upload
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Upload diamond list with offers
                </h3>
              </p>
            </div>

            <div class="workflow_img_wrapper">
              <img class="workflow__imgArrow" src="/Icons/Arrowone.svg" />
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon5.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Purchase
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Get An Instant Purchase Order
                </h3>
              </p>
            </div>

            <div class="workflow_img_wrapper">
              <img class="workflow__imgArrow" src="/Icons/Arrowtwo.svg" />
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon6.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Deliver
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Deliver To Local IGI Gem Lab
                </h3>
              </p>
            </div>
          </div>

          {/* old code to be commented */}

          {/* <div class=" flex justify-center md:justify-between items-center self-stretch flex-grow-0 flex-shrink-0 flex-wrap  space-y-5">
            <div class="system__block flex justify-start items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon4.png" />
              <p class="flex-grow-0 flex-shrink-0 w-[211px] h-[73px]">
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                Upload
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                Upload diamond list with offers
                </h3>
              </p>
            </div>
            
            <img src="/Icons/Arrowone.svg" />
            <div class="system__block flex justify-start items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
            <img class="left-0" src="/Images/Icon5.png" />

              <p class="flex-grow-0 flex-shrink-0 w-[211px] h-[73px]">
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                Purchase
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                Get an instant purchase order
                </h3>
              </p>
            </div>
            <img src="/Icons/Arrowtwo.svg" />
            <div class="system__block flex justify-start items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
            <img class="left-0" src="/Images/Icon6.png" />

              <p class="flex-grow-0 flex-shrink-0 w-[211px] h-[73px]">
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                Deliver
                </h2>
                <h3 class="text-xs font-medium text-left pr-2 capitalize text-[#6b6b6b]">
                Deliver to local IGI gem lab
                </h3>
              </p>
            </div>
          </div> */}
          {/* commented code end */}

          <div class="flex flex-col text-center w-full my-20">
            <h1 class="md:text-[44px] sm:text-3xl text-3xl font-bold mb-3 text-gray-900 ">
              {/* flex-grow-0 flex-shrink-0 text-[44px] font-bold text-center text-[#2b2b3a] */}
              Prompt Payments
            </h1>
          </div>

          <div className="workflow workflow__promptPayment">
            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon7.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Payments
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  We Pay Quick
                </h3>
              </p>
            </div>

            <div class="workflow_img_wrapper">
              <img class="workflow__imgArrow" src="/Icons/Arrowone.svg" />
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon8.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  No Negotiation
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  We Do Not Negotiate
                </h3>
              </p>
            </div>

            <div class="workflow_img_wrapper">
              <img class="workflow__imgArrow" src="/Icons/Arrowtwo.svg" />
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon9.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Return
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  We Never Reject Or Return A Diamond If Quality Matches
                </h3>
              </p>
            </div>
          </div>

          {/* old code */}

          {/* <div class=" flex justify-center md:justify-between items-center self-stretch flex-grow-0 flex-shrink-0 flex-wrap  space-y-5">
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon7.png" />
              <p class="flex-grow-0 flex-shrink-0 w-[211px] h-[73px]">
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Payments
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  We Pay Quick
                </h3>
              </p>
            </div> */}
          {/* <img src="/Icons/Arrowone.svg" /> */}
          {/* <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon8.png" />

              <p class="flex-grow-0 flex-shrink-0 w-[211px] h-[73px]">
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  No Negotiation
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  We Do Not Negotiate
                </h3>
              </p>
            </div> */}
          {/* <img src="/Icons/Arrowtwo.svg" /> */}
          {/* <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white border border-[#d8d8d8]">
              <img class="left-0" src="/Images/Icon9.png" />

              <p class="flex-grow-0 flex-shrink-0 w-[211px] h-[73px]">
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Return
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  We never reject or return
                  <br /> a diamond if quality matches
                </h3>
              </p>
            </div> */}
          {/* </div> */}

          {/* old code end */}

          <div class="flex flex-col text-center w-full mb-20">
            <p class="lg:w-2/3 mx-auto mt-16 leading-relaxed text-base text-[22px]">
              To sell to us, simply offer us your best prices.
            </p>
          </div>
          <button class="large__button selling__button flex mx-auto mt-16 text-white relative focus:outline-none bg-[#703dba]">
            {/* <p class="flex-grow-0 flex-shrink-0 capitalize text-white">
              Start Selling
            </p> */}
            <a
              class="flex-grow-0 flex-shrink-0 capitalize text-white"
              // style={{ color: "#2b2b3a" }}
              href={process.env.REACT_APP_DSE_SELL_DOMAIN + "/#/login"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Start Selling
            </a>
          </button>
        </div>
      </section>
      {/* <section id="How-to-buy" class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-col px-5 py-4 justify-center items-center">
          <div class="w-full md:w-2/3 flex flex-col mb-10 items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl 2xl:text-6xl mb-4 text-gray-900 flex-grow-0 flex-shrink-0 md:text-[44px] font-bold">
              <sapn class="text-[#703dba]">How</sapn> <sapn>we buy</sapn>
            </h1>
            <text class="mb-8 leading-relaxed text-[22px] mt-[30px] px-[60px] text-[#6b6b6b]">
              Take a look at our detailed buying process
            </text>
          </div>
          <img
            class=" object-cover object-center rounded "
            alt="hero"
            src="/Images/Imagetwo.png"
          />
        </div>
        <div class="container flex flex-wrap justify-center md:justify-between md:px-2 items-center self-stretch flex-grow-0 flex-shrink-0">
          
          <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white desktop__numbers">
            <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
              <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute left-[144.53px] top-[9.53px]"
                preserveAspectRatio="xMidYMid meet"
              >
                <circle cx="45" cy="45" r="45" fill="#FAFAFA"></circle>
              </svg>
              <p class="absolute left-[177px] top-[25px] text-5xl font-bold text-center capitalize text-[#703dba]">
                1
              </p>
            </div>
          </div>
          <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white desktop__numbers">
            <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
              <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute left-[144.53px] top-[9.53px]"
                preserveAspectRatio="xMidYMid meet"
              >
                <circle cx="45" cy="45" r="45" fill="#FAFAFA"></circle>
              </svg>
              <p class="absolute left-[177px] top-[25px] text-5xl font-bold text-center capitalize text-[#703dba]">
                2
              </p>
            </div>
          </div>
          <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white desktop__numbers">
            <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
              <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute left-[144.53px] top-[9.53px]"
                preserveAspectRatio="xMidYMid meet"
              >
                <circle cx="45" cy="45" r="45" fill="#FAFAFA"></circle>
              </svg>
              <p class="absolute left-[177px] top-[25px] text-5xl font-bold text-center capitalize text-[#703dba]">
                3
              </p>
            </div>
          </div>
        </div>

        old code 
        <div class="container md:px-24 py-14 mx-auto arrange_padding_pixels ">
          <div className="workflow">

            <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white mobile__numbers">
              <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
                <svg
                  width="90"
                  height="90"
                  viewBox="0 0 90 90"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute left-[144.53px] top-[9.53px]"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <circle cx="45" cy="45" r="45" fill="#FAFAFA"></circle>
                </svg>
                <p class="absolute left-[177px] top-[25px] text-5xl font-bold text-center capitalize text-[#703dba]">
                  1
                </p>
              </div>
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon4.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Upload
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Upload A Confidential List Of Diamonds With Your Price
                </h3>
              </p>
            </div>

            <div class="workflow_img_wrapper">
              <img class="workflow__imgArrow" src="/Icons/Arrowone.svg" />
            </div>

            <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white mobile__numbers">
              <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
                <svg
                  width="90"
                  height="90"
                  viewBox="0 0 90 90"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute left-[144.53px] top-[9.53px]"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <circle cx="45" cy="45" r="45" fill="#FAFAFA"></circle>
                </svg>
                <p class="absolute left-[177px] top-[25px] text-5xl font-bold text-center capitalize text-[#703dba]">
                  2
                </p>
              </div>
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon5.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Purchase
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Get An Instant Purchase Order
                </h3>
              </p>
            </div>

            <div class="workflow_img_wrapper">
              <img class="workflow__imgArrow" src="/Icons/Arrowtwo.svg" />
            </div>

            <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[380px] relative gap-[30px] px-5 py-2.5 rounded-[20px] bg-white mobile__numbers">
              <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
                <svg
                  width="90"
                  height="90"
                  viewBox="0 0 90 90"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute left-[144.53px] top-[9.53px]"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <circle cx="45" cy="45" r="45" fill="#FAFAFA"></circle>
                </svg>
                <p class="absolute left-[177px] top-[25px] text-5xl font-bold text-center capitalize text-[#703dba]">
                  3
                </p>
              </div>
            </div>

            <div className="workflow__items">
              <img class="workflow__img" src="/Images/Icon6.png" />
              <p>
                <h2 class="md:text-[24px]text-xl text-2xl font-bold text-left capitalize text-[#703dba] mb-2">
                  Deliver
                </h2>
                <h3 class="text-xs font-medium text-left capitalize text-[#6b6b6b]">
                  Deliver To A Local IGI Gem Lab, We Pay Upon Inspection
                </h3>
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="text-[44px] font-medium title-font mb-4 text-gray-900">
              <span class="text-[44px] font-bold text-center text-[#703dba]">
                What
              </span>{" "}
              <span class="text-[44px] font-bold text-center text-[#2b2b3a]">
                We Buy
              </span>
            </h1>
            <text class=" mx-auto mt-[30px] px-[60px] flex-grow-0 flex-shrink-0 md:w-[768px] sm:w-[27px] sm:text-[11px] md:text-[22px] text-center text-[#6b6b6b]">
              Diamonds above $750 per stone must be GIA Graded. We buy graded
              and ungraded diamonds below $750 per stone
            </text>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/2">
              <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                <img src="/Images/Image3.png" />
              </div>
            </div>
            <div class="p-4 lg:w-1/2">
              <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                <img src="/Images/Image4.png" />
              </div>
            </div>
            <div class="p-4 lg:w-1/2">
              <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                <img src="/Images/Image5.png" />
              </div>
            </div>
            <div class="p-4 lg:w-1/2">
              <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                <img src="/Images/Image6.png" />
              </div>
            </div>
            <button
              class="large__button download__pdf flex justify-center  mx-auto items-center flex-grow-0 flex-shrink-0 relative bg-[#703dba]"
              onClick={handleDownload}
            >
              <p class="flex-grow-0 flex-shrink-0 text-base font-bold text-left capitalize text-white">
                Download PDF Guide
              </p>
            </button>
          </div>
        </div>
      </section>
      <section id="BuyingProcess" class="text-gray-600 body-font  ">
        <div>
          <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[30px]">
            <p class="flex-grow-0 flex-shrink-0 2xl:text-6xl text-[44px] font-bold text-center text-[#2b2b3a]">
              Buying Process
            </p>
            <text class="flex-grow-0 flex-shrink-0 text-center mt-[30px] px-[60px] mx-auto md:w-[883px] text-[22px] text-center text-[#6b6b6b]">
              We buy statistical samples of all diamonds from established
              vendors, for a regulated commodity using an audited price
              discovery process
            </text>
          </div>
          <div class="flex container flex-col my-5 justify-center md:mx-auto  flex-grow-0 flex-shrink-0 md:w-[1292px] gap-5">
            <Accordion
              style={{
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                padding: "20px",
              }}
            >
              <AccordionSummary
                expandIcon={<img src="/Images/Chevron.png" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography class="text-2xl text-[#2b2b3a]">
                  Upload a private list of diamonds with your best COD price.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  For next day delivery to your IGI Lab ONLY.
                </Typography>
                <Typography>
                  Round, 0.18 to 2.05 carats, D-L, FL-SI2, EX-VG Cut.
                </Typography>
                <Typography>
                  Diamonds above $750/ct must already be GIA Graded.
                </Typography>
                <Typography>
                  Your lists are confidential, and so are our bids.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderWidth: 0,
                padding: "20px",
                // margin: 30,
                padding: 20,
              }}
            >
              <AccordionSummary
                expandIcon={<img src="/Images/Chevron.png" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography class="text-2xl text-[#2b2b3a]">
                  We instantly buy or bid on some, or all of your diamonds.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We may accept your asking price, or make an instant cash bid.
                </Typography>
                <Typography>
                  The commodity is not jewelry, we need all types of diamonds.
                </Typography>
                <Typography>
                  Inclusions welcome, printed GIA paperwork not required.
                </Typography>
                <Typography>
                  Bids change frequently as we fill our commodity orders.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                padding: "20px",
              }}
            >
              <AccordionSummary
                expandIcon={<img src="/Images/Chevron.png" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography class="text-2xl text-[#2b2b3a]">
                  Accept or reject our offer.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Our system generates an instant purchase order.
                </Typography>
                <Typography>
                  Deliver to your local IGI Lab – they verify that the diamonds
                  match the certs, or grade ungraded diamonds for us.
                </Typography>
                <Typography>
                  We pay COD, and never return a matching diamond.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <button class="large__button flex justify-center mx-auto items-center flex-grow-0 flex-shrink-0 relative bg-[#703dba]">
              {/* <p class="flex-grow-0 flex-shrink-0 capitalize text-white">
                Start Process
              </p> */}
              <a
                class="flex-grow-0 flex-shrink-0 capitalize text-white"
                style={{ color: "#2b2b3a" }}
                href={process.env.REACT_APP_DSE_SELL_DOMAIN + "/#/login"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Start Process
              </a>
            </button>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container   mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font sm:text-4xl 2xl:text-6xl text-[44px] mb-4 text-left font-bold text-gray-900">
              The Brilliant Mind Behind Diamond Standard
            </h1>
            <p class="mb-8 leading-relaxed text-left">
              Cormac Kinney is a quant finance pioneer known for inventing
              heatmaps and perfecting sentiment analysis for statistical
              arbitrage.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end">
              <div class="self-stretch flex-grow-0 flex-shrink-0 w-[3px] rounded-tr-sm rounded-br-sm bg-[#703dba] "></div>
              &nbsp;&nbsp;
              <div class="relative mr-4 md:w-full lg:w-full ">
                <p class="title-font text-left text-[26px] sm:text-[1] text-gray-900 ">
                  After 30 years in Fintech and all my successful ventures,
                  Diamond Standard is my masterpiece.
                </p>
              </div>
            </div>
            <br />
            <div class="flex w-full md:justify-start justify-center items-end">
              <div class="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
                <p class="title-font text-left font-medium text-gray-900 ">
                  Cormac Kinney
                </p>
                <p class="mb-8 leading-relaxed text-left">
                  Diamond Standard Founder and CEO
                </p>
              </div>
            </div>
            <div class="flex lg:flex-row md:flex-col">
              <button class="flex mx-auto w-30 h-12 item-center px-6 py-2.5 rounded-lg border border-[#703dba]">
                {/* <span class="text-[#703dba] capitalize  font-bold">
                  Know More
                </span> */}
                <a
                  class="text-[#703dba] hover:text-[#703dba] capitalize  font-bold"
                  // style={{ color: "#2b2b3a" }}
                  href="https://diamondstandard.co/about-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div class="lg:max-w-lg lg:w-full md:w-1/2 ">
            <img
              class="object-cover object-center "
              alt="hero"
              src="/Images/Image7.png"
            />
            <div class="flex justify-center mt-4 mx-auto items-start flex-grow-0 flex-shrink-0 space-x-5">
              <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <h4 class="flex-grow-0 flex-shrink-0 text-2xl md:text-4xl text-center text-[#8d64c8]">
                  4,000+
                </h4>
                <p class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                  <span class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                    U.S. patent
                  </span>
                  <br />
                  <span class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                    citations
                  </span>
                </p>
              </div>
              <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <h4 class="flex-grow-0 flex-shrink-0 text-2xl md:text-4xl text-center text-[#8d64c8]">
                  100+
                </h4>
                <p class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                  <span class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                    institutional trading
                  </span>
                  <br />
                  <span class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                    systems designed
                  </span>
                </p>
              </div>
              <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                <h4 class="flex-grow-0 flex-shrink-0 text-2xl md:text-4xl text-center text-[#8d64c8]">
                  $500M+
                </h4>
                <p class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                  <span class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                    raised
                  </span>
                  <br />
                  <span class="flex-grow-0 flex-shrink-0 text-base text-center text-[#2b2b3a]">
                    in capital
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="About" class="text-gray-600 body-font overflow-hidden">
        <div class="container   px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="text-[44px] font-bold text-center 2xl:text-6xl text-[#2b2b3a] mb-4 text-gray-900">
              About Us
            </h1>
            <text class="text-[22px] mt-[30px] px-[60px] text-center text-[#6b6b6b]">
              How the Diamond Commodity Market Works
            </text>
          </div>

          <div class="-my-8 divide-y-2 divide-gray-100 ">
            <div class="py-8 flex flex-wrap md:flex-nowrap aboutus__block">
              <div class="md:flex-grow overflow-hidden aboutus__block">
                {/* <div class="flex-grow-0 flex-shrink-0 w-[90px] h-[90px]">
                  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
                    <circle cx="45" cy="45" fill="#FAFAFA"></circle>
                    </svg>
                    <p class="absolute left-8 top-[15px] text-5xl font-bold text-center capitalize text-[#703dba]">1</p>
                </div> */}

                <div className="aboutus__number">1</div>

                <p class=" flex leading-relaxed">
                  Diamond Standard is the primary dealer for diamond
                  commodities. Like gold refiners, our job is to create and
                  offer a fungible unit of trade. Refiners offer a kilo bar of
                  0.999 purity, we offer Diamonds Standard Coins and Bars each
                  containing equivalent gemological content.
                </p>
                {/* <a class="text-indigo-500 inline-flex items-center mt-4">
                  Learn More
                  <svg
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a> */}
              </div>
            </div>
            <div class="py-8 flex flex-wrap md:flex-nowrap">
              {/* <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span class="font-semibold title-font text-gray-700">
                  CATEGORY
                </span>
                <span class="mt-1 text-gray-500 text-sm">12 Jun 2019</span>
              </div> */}
              <div class="md:flex-grow aboutus__block">
                {/* <h2 class="text-[24px] font-medium text-gray-900 title-font mb-2">
                  Meditation bushwick direct trade taxidermy shaman
                </h2> */}
                <div className="aboutus__number">2</div>
                <p class="leading-relaxed">
                  As a market maker for loose diamonds, we bid on millions of
                  diamond varietes, and force regular price discovery, by
                  purchasing a public and statistically valid sample of
                  diamonds. The diamond market tell us the cost.
                </p>
                {/* <a class="text-indigo-500 inline-flex items-center mt-4">
                  Learn More
                  <svg
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a> */}
              </div>
            </div>
            <div class="py-8 flex flex-wrap md:flex-nowrap ">
              {/* <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span class="font-semibold title-font text-gray-700">
                  CATEGORY
                </span>
                <span class="text-sm text-gray-500">12 Jun 2019</span>
              </div> */}
              <div class="md:flex-grow aboutus__block">
                {/* <h2 class="text-[24px] font-medium text-gray-900 title-font mb-2">
                  Woke master cleanse drinking vinegar salvia
                </h2> */}
                <div className="aboutus__number">3</div>
                <p class="leading-relaxed">
                  <p>
                    As a primary dealer, we offer new Diamond Standard Coins to
                    consumers at a daily fix price determined by secondary
                    market trading.
                  </p>

                  <p>
                    We offer Coins to institutional investors via a sales desk.
                  </p>

                  <p>
                    Approved market makers and brokers can participate in a
                    weekly primary dealer auction, which establishes the
                    discount such participants require in order to provide
                    liquidity or distribution
                  </p>
                </p>
                {/* <a class="text-indigo-500 inline-flex items-center mt-4">
                  Learn More
                  <svg
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container   px-5 py-24 mx-auto">
          <div class="flex flex-wrap -m-2 ">
            <div class="p-2 lg:w-1/2 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-3xl bg-[#fff7ac]/20">
                <img
                  alt="team"
                  class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                  src="/Images/Icon5-1.png"
                />
                <div class="flex-grow">
                  <h2 class="text-[#703dba] text-2xl title-font font-medium">
                    Note
                  </h2>
                  <p class="text-gray-500">
                    {" "}
                    We do not set the market price for the diamond commodities.
                  </p>
                </div>
              </div>
            </div>
            <div class="p-2 lg:w-1/2 md:w-1/2 w-full">
              <div class="h-full flex items-center border-gray-200 border p-4 rounded-3xl bg-[#fff7ac]/20">
                <img
                  alt="team"
                  class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                  src="/Images/Icon5-1.png"
                />
                <div class="flex-grow">
                  <h2 class="text-[#703dba] text-2xl title-font font-medium">
                    Note
                  </h2>
                  <p class="text-gray-500">
                    {" "}
                    We do not participate in the secondary market (such as
                    trading on exchanges)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font overflow-hidden">
        <div class="container   px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full">
            <h1 class="text-[44px] font-bold 2xl:text-6xl text-center text-[#2b2b3a]  mb-4 text-gray-900">
              <span>The Diamond</span>{" "}
              <span class="text-[#703dba]">Commodity</span>
            </h1>
            <text class="text-[22px] mt-[30px] px-[60px] my-4 text-center text-[#6b6b6b]">
              World's Only Regulator-Approved Diamond Commodity Now Available to
              Investors
            </text>
          </div>
          <div class="lg:w-15 mx-auto flex flex-wrap">
            <img
              alt="ecommerce"
              class="lg:w-1/2 w-full lg:h-auto object-cover object-center rounded"
              src="/Images/Image8.png"
            />
            <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <h1 class="flex-grow-0 flex-shrink-0 w-[616px] text-2xl font-bold text-left capitalize text-[#703dba]">
                  Diamond Standard
                </h1>
                <p class="leading-relaxed font-medium text-left text-[#6b6b6b]">
                  Diamond Standard Co. is the creator of the world s first and
                  only regulator approved diamond commodity. By unlocking
                  diamonds as a market traded asset, Diamond Standard helps
                  investors to access a natural resource currently worth $1.2
                  trillion more than all the world s silver and platinum
                  combined. A breakthrough deliverable hard asset, the diamond
                  commodity provides diversification and a new store of wealth
                  for institutional and individual investors, while bringing
                  transparency and efficiency to the diamond supply chain.
                </p>
                <br />
                <span class="mt-5 text-lg font-medium text-left text-[#6b6b6b]">
                  Learn more at DiamondStandard.co
                </span>
              </div>
              <br />
              <div class="mx-auto flex border-2 border-gray-200 px-2 py-4 rounded-lg">
                <img class="w-20 h-20" src="/Images/Icon7-1.png" />{" "}
                <p class="leading-relaxed font-medium text-left text-[#6b6b6b]">
                  The Diamond Standard Exchange is the venue where Diamond
                  Standard automatically bids for millions of different types of
                  diamonds, to purchase all diamonds for the commodities. The
                  buying process is regulator approved, and audited by Deloitte.
                </p>
              </div>
              <br />
              <div class="mx-auto flex border-2 border-gray-200 px-2 py-4 rounded-lg">
                <img class="w-20 h-20" src="/Images/Icon8-1.png" />{" "}
                <p class="leading-relaxed font-medium text-left text-[#6b6b6b]">
                  The diamonds are graded by the GIA, and the coins and bars are
                  assembled inside the IGI Labs. We buy from registered vendors
                  only. Regulators, Auditors, and Strategic Relationship.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container   px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 md:text-[44px] font-bold text-center text-[#2b2b3a]">
              Regulators, Auditors, and Strategic Relationships
            </h1>
          </div>
          <div class=" grid grid-cols-1 gap-y-10 gap-x-5 sm:grid-cols-4 lg:grid-cols-5 xl:gap-x-12">
            {/* <div class="flex justify-center">
              <img src="/Images/Image9.png" />
            </div> */}
            <div class="flex justify-center">
              <img src="/Images/Image10.png" />
            </div>
            <div class="flex justify-center">
              <img src="/Images/Image11.png" />
            </div>
            <div class="flex justify-center">
              <img src="/Images/Image12.png" />
            </div>
            <div class="flex justify-center">
              <img src="/Images/Image13.png" />
            </div>
            {/* <div class="flex justify-center">
              <img src="/Images/Image14.png" />
            </div> */}
            <div class="flex justify-center">
              <img src="/Images/Image15.png" />
            </div>
          </div>
        </div>
      </section>
      <footer class="text-gray-600 bg-[#f5f4ff] body-font">
        <div class="container px-3 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <img src="/Images/DS.png" />
              <span class="ml-3 text-xl"></span>
            </a>
            <p class="mt-2 text-xs text-left  text-gray-500">
              Diamond Standard Ltd. is a Digital Assets Business licensed by the
              Bermuda Monetary Authority to issue, sell and redeem tokens and
              digital assets. License number 53,943
            </p>
          </div>
          <div class="flex-grow flex flex-wrap w-64 md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center justify-end">
            <div class="lg:w-2/5  px-4 space-x-4 text-left">
              <h2 class="title-font text-16 font-medium text-gray-900 tracking-widest mb-3 ml-3.5  diamond__market__text">
                Diamond Market
              </h2>
              <nav class="list-none mb-10 text-14">
                <li>
                  <a
                    href="https://diamondstandard.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-gray-600 hover:text-gray-800"
                  >
                    Investors:{" "}
                    <span class="hover:text-[#703dba]">Diamond Standard</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.recycling.diamondstandard.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-gray-600 hover:text-gray-800"
                  >
                    Consumers:
                    <span class="hover:text-[#703dba]">DS Recycling</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.diamondstandard.co/institutional"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-gray-600 hover:text-gray-800"
                  >
                    Institutions:
                    <span class="hover:text-[#703dba]">DS Institutional</span>
                  </a>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div>
          <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p class="text-gray-500 text-sm text-center sm:text-left">
              <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                <a
                  href="https://www.facebook.com/diamondstandardco"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-gray-500 hover:text-[#703dba]"
                >
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com/DiamondStandard"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="ml-3 text-gray-500 hover:text-[#703dba]"
                >
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    {/* <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path> */}
                    <path d="M 2.8671875 3 L 9.7363281 12.818359 L 2.734375 21 L 5.3808594 21 L 10.919922 14.509766 L 15.460938 21 L 21.371094 21 L 14.173828 10.697266 L 20.744141 3 L 18.138672 3 L 12.996094 9.0097656 L 8.7988281 3 L 2.8671875 3 z"></path>
                  </svg>
                </a>
                {/* <a class="ml-3 text-gray-500 hover:text-[#703dba]">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a> */}
                <a
                  href="https://www.linkedin.com/company/11788990"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="ml-3 text-gray-500 hover:text-[#703dba]"
                >
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="0"
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="none"
                      d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                    ></path>
                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                  </svg>
                </a>
              </span>
            </p>
          </div>
          <hr class="w-full " />
          <div>
            <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
              <p class="text-gray-500 text-sm text-center sm:text-left">
                ©{new Date().getFullYear()}, DIAMOND STANDARD INC. All Rights Reserved.
              </p>
              <span class="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">
                <a
                  href="https://diamondstandard.co/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="hover:text-[#703dba]">Privacy Policy</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
      <Announcement show={showAnn} handleCloseAnn={handleCloseAnn} />
    </div>
  );
}
