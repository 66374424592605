import React from "react";
import { BrowserRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Routes from "./routes/Routes";
import { IntlProvider } from "react-intl";
import messages from "./assests/Local/messages";
import { MaterialSnackbar } from "./components/Snackbar/Snackbar";
import Loader from "./components/Loader/Loader";
import { connect } from "react-redux";
import { setCurrentLang } from "./store/Lang/LangAction";
import { setAnnouncement } from "./store/Announcement/AnnouncementAction";
import { axiosInstance } from "./network/apis/index";
import AOS from "aos";

class App extends React.Component {
  componentDidMount() {
    axiosInstance
      .get("/getSettingDetails")
      .then((res) => 
      {
        console.log(res)
        this.props.setAnnouncement(res.data.setting)
      })
      .catch((error) => console.log("error ", error));

  }
  render() {
    const { lang, loading } = this.props;
    return (
     <Routes/>
    );
  }
}

const mapStateToProps = ({ lang, loading }) => ({
  lang,
  loading,
});
const mapDespatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentLang, setAnnouncement }, dispatch);

export default connect(mapStateToProps, mapDespatchToProps)(App);
